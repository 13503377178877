<template>
    <div class="order">
        <el-card>
            <template #header>
                <span>订单管理</span>
            </template>
            <div class="content">
                <el-select filterable clearable v-model="merchantid" placeholder="请选择商户" size="small"
                    style="margin-right:20px;" v-if="ismerchant != 1" @change="GetStore">
                    <el-option v-for="item in merchants" :key="item" :value="item.ID" :label="item.Merchant"></el-option>
                </el-select>
                <el-select filterable clearable v-model="storeid" placeholder="请选择店铺" size="small"
                    style="margin-right:20px;" multiple @visible-change="GetStore(merchantid)"
                    :disabled="(merchantid || mid) ? false : true">
                    <el-option v-for="item in stores" :key="item" :value="item.ID" :label="item.Store"></el-option>
                </el-select>
                <el-input v-model="keyword" placeholder="请输入订单号/交易流水号/手机号/设备号" size="small"
                    style="margin-right:20px;width:350px"></el-input>
                <el-select filterable clearable v-model="paytype" placeholder="请选择支付方式" size="small"
                    style="margin-right:20px;">
                    <el-option value="1" label="支付宝扫码支付"></el-option>
                    <el-option value="2" label="支付宝余额支付"></el-option>
                    <el-option value="3" label="微信扫码支付"></el-option>
                    <el-option value="4" label="微信余额支付"></el-option>
                    <el-option value="5" label="美团核销"></el-option>
                </el-select>
                <el-select filterable clearable v-model="status" placeholder="请选择订单状态" size="small"
                    style="margin-right:20px;">
                    <el-option label="下单中" value="0"></el-option>
                    <el-option label="进行中" value="1"></el-option>
                    <el-option label="已完成" value="2"></el-option>
                </el-select>
                <el-select filterable clearable v-model="dstatus" placeholder="请选择设备状态" size="small"
                    style="margin-right:20px;">
                    <el-option label="已激活" value="1"></el-option>
                    <el-option label="已停用" value="2"></el-option>
                </el-select>
                <el-date-picker size="small" v-model="range" type="daterange" unlink-panels range-separator="至"
                    start-placeholder="开始时间" end-placeholder="结束时间" :shortcuts="shortcuts" style="margin-right: 10px"
                    format="YYYY-MM-DD"
                    value-format="YYYY-MM-DD"
                    @selection-change="CheckSelection"></el-date-picker>
                <el-button type="primary" @click="init" size="small">搜索</el-button>
            </div>
            <div style="padding:20px 20px 0 0;width:30%;display:flex;justify-content:space-between;">
                <span>订单金额:{{ total ? total : 0 }}元</span>
            </div>
            <div class="content-main">
                <el-table :data="tabledata" border style="width: 100%; margin: 1em 0" v-loading="load"
                    element-loading-spinner="el-icon-loading" element-loading-text="加载中..." size="small">
                    <el-table-column label="订单编号" prop="OrderUID" width="150px"></el-table-column>
                    <el-table-column label="交易流水号" prop="Bill" width="250px">
                        <template #default="scope">
                            <span v-for="item in scope.row.Bill" :key="item">{{ item }}<br></span>
                        </template>
                    </el-table-column>
                    <el-table-column label="用户手机">
                        <template #default="scope">
                            <span>{{ scope.row.Mobile }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="订单金额(元)" prop="Price">
                        <template #default="scope">
                            <span>{{ scope.row.Price / 100 }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="支付方式">
                        <template #default="scope">
                            <span v-if="scope.row.Type == 1">支付宝扫码支付</span>
                            <span v-if="scope.row.Type == 2">支付宝余额支付</span>
                            <span v-if="scope.row.Type == 3">微信扫码支付</span>
                            <span v-if="scope.row.Type == 4">微信余额支付</span>
                            <span v-if="scope.row.Type == 5">美团核销</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="设备号" prop="DeviceCode"></el-table-column>
                    <el-table-column label="店铺" prop="Store"></el-table-column>
                    <el-table-column label="订单时间" width="250px">
                        <template #default="scope">
                            <span>开始时间：{{ formatDate(scope.row.StartDTime) }}</span><br>
                            <span>结束时间：{{ formatDate(scope.row.ExpiredDTime) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="时长(分钟)">
                        <template #default="scope">
                            <span>{{ (scope.row.TotalTime) / 60 }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="订单状态">
                        <template #default="scope">
                            <span v-if="scope.row.OrderStat == 0">下单中</span>
                            <span v-if="scope.row.OrderStat == 1">订单进行中</span>
                            <span v-if="scope.row.OrderStat == 2">订单已完成</span>

                        </template>
                    </el-table-column>
                    <el-table-column label="是否正常">
                        <template #default="scope">
                            <div v-if="scope.row.OrderStat == 1">
                                <span v-if="(scope.row.ExpiredDTime < new Date())">异常</span>
                                <span v-else>正常</span>
                            </div>
                            <div v-else> / </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作">
                        <template #default="scope">
                            <el-button type="text" @click="End(scope.row.ID)" v-if="scope.row.OrderStat == 1">完结</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination @current-change="handleCurrentChange" @size-change="handleSizeChange" :current-page="curpage"
                    :page-size="pagesize" :page-sizes="[10, 20, 30, 50, 100]" layout="sizes,total, prev, pager, next, jumper"
                    :total="totalcount" :hide-on-single-page="true" style="text-align:center"></el-pagination>
            </div>
        </el-card>
    </div>
</template>
<script>
import constant from "@/constant"
import Cookies from 'js-cookie'
export default {
    data() {
        return {
            keyword: "",
            paytype: "",
            status: "",
            dstatus: "",
            range: [],
            tabledata: [],
            curpage: 1,
            totalcount: '',
            pagesize: 10,
            storeid: [],
            stores: [],
            merchants: [],
            merchantid: '',
            load: false,
        }

    },
    methods: {
        End(id) {
            this.$confirm('确定要完结该订单么？', '提示', {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            }).then(() => {
                this.axios.get(constant.endorder, {
                    headers: {
                        "content-type": "application/json"
                    },
                    params: {
                        id: id
                    }
                }).then((response) => {
                    console.log(response.data);
                    if (response.data == 'OK') {
                        this.$message.success("操作成功");
                        this.$router.go(0);
                    } else {
                        this.$message.error("操作失败");
                    }
                });
            }).catch(() => {
                return false;
            });
        },
        handleCurrentChange(val) {
            this.curpage = val;
            this.init();
        },
        handleSizeChange(val) {
            this.pagesize = val;
            this.init();
        },
        init() {
            this.load = true;
            this.axios.get(constant.orderlist, {
                headers: {
                    "content-type": "application/json"
                },
                params: {
                    curpage: this.curpage,
                    pagesize: this.pagesize,
                    keyword: this.keyword,
                    status: this.status,
                    dstatus: this.dstatus,
                    range: JSON.stringify(this.range),
                    paytype: this.paytype,
                    storeid: this.storeid,
                    merchantid: this.merchantid,
                    ismerchant: this.ismerchant,
                    mid: this.mid,
                }
            }).then((response) => {
                console.log(response.data);
                this.tabledata = response.data.list;
                this.curpage = response.data.curpage * 1;
                this.totalcount = response.data.totalcount * 1;
                this.total = response.data.total * 1;
                this.load = false;
            });
        },
        formatDate(date) {
            var datetime = new Date(date * 1000);
            var year = datetime.getFullYear();
            var month = ("0" + (datetime.getMonth() + 1)).slice(-2);
            var day = ("0" + datetime.getDate()).slice(-2);
            var hour = ("0" + datetime.getHours()).slice(-2);
            var minute = ("0" + datetime.getMinutes()).slice(-2);
            var second = ("0" + datetime.getSeconds()).slice(-2);
            return year + "-" + month + "-" + day + " " + hour + ":" + minute + ":" + second;
        },
        GetStore(merchantid) {
            console.log("商户--" + merchantid);
            this.axios.get(constant.allstore, {
                headers: {
                    "content-type": "application/json"
                },
                params: {
                    ismerchant: this.ismerchant,
                    mid: this.mid,
                    merchantid: merchantid
                }
            }).then((response) => {
                console.log(response.data);
                this.stores = response.data;
            });
        }
    },
    created: function () {
        let k = this.$route.query.keyword;
        let m = this.$route.query.merchantid;
        this.keyword = k;
        this.merchantid = m;
        console.log("k--" + this.keyword + "m--" + this.merchantid);
        this.ismerchant = Cookies.get("IsMerchant");
        this.mid = Cookies.get("mid");
        this.init();
        this.axios.get(constant.allmerchant, {
            headers: {
                "content-type": "application/json"
            },
        }).then((response) => {
            console.log(response.data);
            this.merchants = response.data;
        });
    }

}
</script>
<style scoped>
.content {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
</style>